<template>
  <div class="relative">
    <ImageStrapiImage v-if="data.image" :data="data.image.data" class="w-full rounded-heavy object-cover" />
    <ImageBananaImage v-if="hasBanana" :data="data" />
  </div>
</template>

<script setup lang="ts">
import type { SimpleImage } from "~/types/strapi/components/layout-sections/interfaces/SimpleImage";

const props = defineProps<{
  data: SimpleImage;
}>();

const hasBanana = computed(() => props.data.bananaImage?.data !== null && props.data.bananaImage?.data !== undefined);
</script>

<style lang="scss" scoped></style>
